<template>
  <div v-if="Object.keys(formData).length" class="componentWrapper">
    <div class="line">
      <div class="cdt_inputWrapper list">
        <label>Welke disciplines beheers je?</label>
        <div class="wrapperr">
          <div :class="{ active: formData.userProfile.disciplines.includes(i) }" class="listItem" @click="updateArray({ value: i, array: 'disciplines' })" v-for="i in disciplines" :key="i">
            <span>
              {{ i }}
            </span>

            <svg :class="{ hidden: !formData.userProfile.disciplines.includes(i) }" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
            </svg>
          </div>
        </div>
      </div>
      <!-- <div class="divider"></div> -->
    </div>

    <div class="line">
      <div class="cdt_inputWrapper list">
        <label>Berekeningen</label>
        <div class="wrapperr">
          <div :class="{ active: formData.userProfile.berekeningen?.includes(i) }" class="listItem" @click="updateArray({ value: i, array: 'berekeningen' })" v-for="i in berekeningen" :key="i">
            <span>
              {{ i }}
            </span>

            <svg :class="{ hidden: !formData.userProfile.berekeningen?.includes(i) }" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
            </svg>
          </div>
        </div>
      </div>
      <!-- <div class="divider"></div> -->
    </div>

    <div class="line">
      <div class="cdt_inputWrapper list">
        <label>Nen2580 werkzaamheden</label>
        <div class="wrapperr">
          <div :class="{ active: formData.userProfile.nen2580Werkzaamheden?.includes(i) }" class="listItem" @click="updateArray({ value: i, array: 'nen2580Werkzaamheden' })" v-for="i in nen2580Werkzaamheden" :key="i">
            <span>
              {{ i }}
            </span>

            <svg :class="{ hidden: !formData.userProfile.nen2580Werkzaamheden?.includes(i) }" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
            </svg>
          </div>
        </div>
      </div>
      <!-- <div class="divider"></div> -->
    </div>
    <div class="line">
      <div class="cdt_inputWrapper">
        <label>Welke tekenprogramma's beheers je?</label>
        <div :class="{ active: formData.userProfile.tekenprogrammas?.includes(i) }" class="listItem" @click="updateArray({ value: i, array: 'tekenprogrammas' })" v-for="i in tekenprogrammas" :key="i">
          <span>
            {{ i }}
          </span>

          <svg :class="{ hidden: !formData.userProfile.tekenprogrammas?.includes(i) }" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
          </svg>
        </div>
      </div>
      <div class="divider"></div>
      <div class="cdt_inputWrapper">
        <label>Aanvullende applicaties</label>
        <div :class="{ active: formData.userProfile.aanvullendeApplicaties?.includes(i) }" class="listItem" @click="updateArray({ value: i, array: 'aanvullendeApplicaties' })" v-for="i in aanvullendeApplicaties" :key="i">
          <span>
            {{ i }}
          </span>

          <svg :class="{ hidden: !formData.userProfile.aanvullendeApplicaties?.includes(i) }" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
          </svg>
        </div>
      </div>
      <!-- <div class="cdt_inputWrapper">
        <label>Website</label>
        <input type="text" placeholder="www.jouwwebsite.nl" v-model="formData.website" />
        <i v-if="formData.website" :class="{ hidden: formData.website.length < 2 }" class="far fa-check checkmark"></i>
      </div>
      <div class="divider"></div>

      <div class="cdt_inputWrapper">
        <label>Iban</label>
        <input v-model="formData.iban" placeholder="IBAN HIER" />
        <i v-if="formData.iban" :class="{ hidden: formData.iban.length < 2 }" class="far fa-check checkmark"></i>
      </div> -->
    </div>

    <!-- <div class="line">
      <div class="cdt_inputWrapper">
        <label>Straatnaam + huisnummer</label>
        <i :class="{ hidden: !formData.straat || !formData.huisnummer }" class="far fa-check checkmark"></i>
        <div class="oneInput">
          <input class="noStyle street" type="text" placeholder="Straat" v-model="formData.straat" />
          <div class="inputWrap">
            <i class="fad fa-house"></i>
            <input class="noStyle lineLeft" type="text" placeholder="13C" v-model="formData.huisnummer" />
          </div>
        </div>
      </div>
      <div class="divider"></div>
      <div class="cdt_inputWrapper">
        <label>Plaats + Postcode</label>
        <i :class="{ hidden: !formData.plaats || !postcodeCheck }" class="far fa-check checkmark"></i>

        <div class="oneInput">
          <input class="noStyle street" type="text" placeholder="Plaatsnaam" v-model="formData.plaats" />
          <div class="inputWrap postcode">
            <i class="fad fa-house"></i>
            <input class="noStyle lineLeft" type="text" placeholder="0000 AA" v-model="formData.postcode" />
          </div>
        </div>
      </div>
    </div> -->
    <!-- 

      /* einde doc */

     -->

    <div class="cdt_note">
      <div class="left">
        <!-- <i class="fal fa-info-circle"></i> -->
        <span>Mis je opties in dit scherm? Stuur dan een mail naar info@cad-tekenaar.com.</span>
      </div>

      <div class="right">
        <i class="fal fa-times-circle"></i>
      </div>
    </div>
    <br />
  </div>
</template>

<script>
export default {
  data() {
    return {
      nen2580Werkzaamheden: ["nen2580 inmeten", "nen2580 rapportage opstellen", "nen2580 plattegrond opstellen"],
      aanvullendeApplicaties: ["lumion", "stabicad", "solibri", "nordined", "cadac", "plant 3d", "indesign", "photoshop", "e-plan", "naviswork", "siemens", "infracad", "3dsmax", "office"],
      berekeningen: ["constructieberekening", "krachtenberekening"],
      disciplines: ["bouwkunde", "werktuigbouwkunde", "installatietechniek", "elektrotechniek", "brandpreventie", "huisvesting", "plattegronden", "concepten -3d print"],
      werkgebied: ["drenthe", "flevoland", "friesland", "gelderland", "groningen", "limburg", "overijssel", "utrecht", "zeeland", "zuid-holland", "noord-brabant", "noord-holland"],
      tekenprogrammas: ["autocad", "revit", "archicad", "solidworks", "bricscad", "inventor", "solid edge", "vectorworks", "rhinoceros", "creo", "microstation", "onshape", "sketchup", "arkey", "tekla"],
    }
  },
  computed: {
    formData: {
      get() {
        return this.$store.state.userProfile.formData
      },
      set(updatedFormData) {
        this.$store.commit("updateFormData", {
          ...updatedFormData,
        })
      },
    },
  },
  methods: {
    updateArray({ value, array }) {
      const arrayToEdit = this.getArrayToEdit(array)
      const valueExistsInArray = arrayToEdit.includes(value)
      if (valueExistsInArray) {
        arrayToEdit.splice(arrayToEdit.indexOf(value), 1)
        this.updateFormData()
      } else {
        arrayToEdit.push(value)
        this.updateFormData()
      }
    },

    updateFormData() {
      // this.formData = {
      //   ...this.formData,
      //   disciplines: this.selectedDisciplines,
      //   werkgebied: this.selectedProvincies,
      // }
      this.$store.dispatch("debounceUpdateUserProfile")
    },

    getArrayToEdit(array) {
      let tempArray
      if (array === "aanvullendeApplicaties") tempArray = this.formData.userProfile.aanvullendeApplicaties
      if (array === "nen2580Werkzaamheden") tempArray = this.formData.userProfile.nen2580Werkzaamheden
      if (array === "disciplines") tempArray = this.formData.userProfile.disciplines
      if (array === "berekeningen") tempArray = this.formData.userProfile.berekeningen
      if (array === "werkgebied") tempArray = this.formData.userProfile.werkgebied
      if (array === "tekenprogrammas") tempArray = this.formData.userProfile.tekenprogrammas
      if (!tempArray) throw new Error("Cannot find an array to select, please check the incoming variable")
      return tempArray
    },
  },
}
</script>

<style lang="sass" scoped>
@import '@/utilities/variables.sass'

.componentWrapper
  .line
    align-items: flex-start !important
    display: flex

.componentWrapper
  .list
    width: 100% !important
    max-width: none !important

.wrapperr
  display: flex
  flex-wrap: wrap
  .listItem
    width: 50%
    overflow: hidden
    &:nth-child(even)
      margin-left: -1px


.listItem
  padding: 8px 10px
  cursor: pointer
  border: 1px solid rgba(0, 0, 0, 0.6)
  margin-bottom: -1px
  display: flex
  align-items: center
  justify-content: space-between
  font-size: 13px
  position: relative
  opacity: 0.6
  transition: .2s
  &.active
    opacity: 1
    border-color: $cdt_blue
    z-index: 1
    background-color: rgba(42, 108, 249, 0.05)
    color: $cdt_blue
    font-weight: 700
  svg
    transition: .3s
    fill: $cdt_green
    font-size: 14px
    height: 1em
    width: 1em
    &.hidden
      transform: translateX(26px)
</style>
