<template>
  <div class="block facturen">
    <h3>Facturen</h3>
    <span>Wil je weten hoe het werkt? <u>Klik hier</u></span>
    <br />
    <div class="facturenList">
      <div class="factuur voegtoe" @click="$emit('addNieuweFactuur')">
        <!-- <i class="fal fa-plus-circle"></i> -->
        <span>Factuur toevoegen</span>
      </div>
      <div @click="$emit('showFactuur', factuur)" class="factuur" v-for="(factuur, index) in orderedFacturen" :key="index">
        <div class="factuurContent">
          <!-- <i class="fad fa-file-invoice"></i> -->
          <span class="price">€{{ factuur.bedragExclBtw }}</span>
          <span class="date"><i class="far fa-calendar"></i> {{ factuur.timestamp.toDate().toLocaleDateString() }}</span>
        </div>
        <!-- <div v-else-if="!factuur.errorWhileCreatingPDFs">Facturen worden gemaakt...</div>
        <div v-else>Corrupt bestand. De factuur is niet verstuurd. Probeer het opnieuw</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/firebase"
import { orderBy } from "lodash"
export default {
  data() {
    return {
      snapshotListener: "",
      facturen: [],
    }
  },
  props: ["userId", "opdrachtId"],
  mounted() {
    this.snapshotListener = db
      .collection(`facturen-incoming`)
      .where("userId", "==", this.userId)
      .where("opdrachtId", "==", this.opdrachtId)
      .onSnapshot((e) => {
        let newArray = []

        e.forEach((g) => {
          newArray.push({
            ...g.data(),
            id: g.id,
          })
        })

        this.facturen = newArray
      })
  },
  computed: {
    orderedFacturen() {
      if (!this.facturen) return []
      return orderBy(this.facturen, "timestamp", "desc")
    },
  },
  beforeUnmount() {
    // Destroy snapshotListener
    this.snapshotListener()
  },
  methods: {
    melding(e) {
      alert(JSON.stringify(e))
    },
  },
}
</script>

<style lang="sass" scoped>
.facturenList
  display: flex
  justify-content: flex-start
  width: 100%
  flex-wrap: wrap
  border: 1px solid rgba(0, 0, 0, 0.1)
  .factuur
    width: 20%
    border-right: 1px solid rgba(0, 0, 0, 0.1)
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
    padding: 20px
    height: 140px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    transition: .3s
    cursor: pointer
    text-align: center
    &:hover
      background-color: rgba(0, 0, 0, 0.04)
    &.voegtoe
      color: blue
      &:hover
        background-color: blue
        color: white
    i
      font-size: 24px
      height: 30px
      color: blue

  .factuurContent
    display: flex
    flex-direction: column
    align-items: center
    .price
      margin-top: 10px
      color: orange
      font-weight: 700
      background-color: rgba(227, 109, 26, 0.1)
      padding: 4px 7px
      font-size: 14px
      border-radius: 1000px
    .date
      margin-top: 5px
      color: rgba(0, 0, 0, 0.4)
      // font-weight: 700
      // background-color: rgba(227, 109, 26, 0.1)
      // padding: 4px 7px
      font-size: 12px
      border-radius: 1000px
      i
        color: rgba(0, 0, 0, 0.4)
        font-size: 12px
        height: 9px
        line-height: 12px
</style>
