<template>
  <div v-if="Object.keys(formData).length" class="componentWrapper">
    <div class="cdt_inputWrapper ">
      <label>Competenties en persoonlijke eigenschappen</label>
      <br />
      <br />
      <div class="wrapperr">
        <div :class="{ active: formData.userProfile.kwaliteien?.includes(i) }" class="listItem" @click="updateArray({ value: i, array: 'kwaliteiten' })" v-for="(i, index) in kwaliteiten" :key="index">
          <span>
            {{ i }}
          </span>
          <svg :class="{ hidden: !formData.userProfile.kwaliteiten?.includes(i) }" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
          </svg>
        </div>
      </div>
    </div>
    <!-- <div class="vert">
        <div class="cdt_inputWrapper">
          <label>Op welke plek werk je?</label>
          <div :class="{ active: formData.userProfile.werkplek?.includes(i) }" class="listItem" @click="updateArray({ value: i, array: 'werkplek' })" v-for="i in werkplek" :key="i">
            <span>
              {{ i }}
            </span>
            <i :class="{ hidden: !formData.userProfile.werkplek?.includes(i) }" class="far fa-check checked"></i>
          </div>
        </div>
        <div class="cdt_inputWrapper">
          <label>Waar ben je naar op zoek?</label>
          <div :class="{ active: formData.userProfile.voorkeurTypeOpdrachten?.includes(i) }" class="listItem" @click="updateArray({ value: i, array: 'voorkeurTypeOpdrachten' })" v-for="i in voorkeurTypeOpdrachten" :key="i">
            <span>
              {{ i }}
            </span>
            <i :class="{ hidden: !formData.userProfile.voorkeurTypeOpdrachten?.includes(i) }" class="far fa-check checked"></i>
          </div>
        </div>
        <div class="cdt_inputWrapper">
          <label>Sta je ook open voor particulier werk?</label>
          <div class="checkboxComponent" @click="toggleParticulierWerk()">
            <input v-model="formData.userProfile.openVoorParticulierWerk" class="checkbox" type="checkbox" placeholder="Ja, ik doe graag particulier werk" />
            <span>Ja, ik sta open voor particuliere opdrachten</span>
          </div>
        </div>
      </div> -->
    <!-- <div class="line">
      <div class="cdt_inputWrapper fullWidth">
        <label>Wat zijn je ambities?</label>
        <textarea v-model="formData.userProfile.ambities" placeholder="Vertel ons kort iets over jezelf en je ambities" />
        <i :class="{ hidden: formData.userProfile.ambities?.length < 20 || !formData.userProfile.ambities }" class="far fa-check checkmark"></i>
      </div>
    </div> -->
    <br />
    <br />
    <div class="cdt_note">
      <div class="left">
        <!-- <i class="fal fa-info-circle"></i> -->
        <span>
          1. Kies persoonlijke eigenschappen die bij de functie passen Dit geeft je de gelegenheid om persoonlijke eigenschappen en competenties te vermelden die perfect aansluiten bij de opdrachten.
          <br />
          <br />
          2. Zet maximaal 7 competenties op je cv Heel veel persoonlijke eigenschappen maken minder indruk dan het benadrukken van enkele echt sterke competenties. Loop dus niet te hard van stapel, het werkt het beste om tussen de drie en zeven persoonlijke eigenschappen te benoemen.
          <br />
          <br />
          3. Noem je competenties in een persoonlijk profiel Wil je graag wat meer toelichting geven over je persoonlijke eigenschappen? Voeg deze toe onder het kopje ‘wat zijn je ambities’ in de sectie ‘Voorkeuren’. Hier kun je in een paar zinnen iets over jouw drijfveren vertellen. Persoonlijke eigenschappen passen hier perfect bij.
          <br />
          <br />
          4. Denk groter dan een enkele opdracht Je persoonlijke eigenschappen laten aansluiten op de opdrachten die je ambieert, werkt! Het levert echter nog meer voordeel op, wanneer je ook competenties benoemt die voor meerdere opdrachtgevers belangrijk zijn.
          <br />
          <br />
          5. Lieg niet over persoonlijke eigenschappen Liegen op je profiel is uiteraard not done. Ook over je persoonlijke eigenschappen dien je dus oprecht te zijn. Ga geen competenties verzinnen omdat ze wel mooi op je profiel staan. Je hebt er immers niets aan als je jezelf een communicatief talent noemt, maar eigenlijk vreselijk introvert bent.</span
        >
        <!-- <span>Selecteer alle opties die voor jou van toepassing zijn. Dus geen of/of, maar en/en.</span> -->
      </div>
      <!-- 
      <div class="right">
        <i class="fal fa-times-circle"></i>
      </div> -->
    </div>
    <br />
  </div>
</template>

<script>
export default {
  data() {
    return {
      voorkeurTypeOpdrachten: ["korte opdrachten", "lange opdrachten", "parttime", "fulltime"],
      werkplek: ["werkplek bij klant", "eigen werkplek", "geen voorkeur"],
      disciplines: ["bouwkunde", "werktuigbouwkunde", "installatietechniek", "elektrotechniek", "brandpreventie", "huisvesting", "plattegronden", "concepten -3d print"],
      werkgebied: ["drenthe", "flevoland", "friesland", "gelderland", "groningen", "limburg", "overijssel", "utrecht", "zeeland", "zuid-holland", "noord-brabant", "noord-holland"],
      tekenprogrammas: ["autocad", "revit", "solidworks", "bricscad", "inventor", "solid edige", "vectorworks", "rhinoceros", "microstation", "onshape", "sketch-up", "arkey"],
      kwaliteiten: ["organisator", "proactief", "optimistisch", "betrokken", "betrouwbaar", "vriendelijk", "efficiënt", "teamspeler", "zelflerend", "secuur", "creatief", "communicatief", "enthousiast", "leiderschap", "goed in samenwerken", "opgeruimd en ordelijk", "praktisch", "leergierig", "resultaatgericht", "hulpvaardig", "geduldig", "flexibel", "doorzettingsvermogen", "creatief", "behendig", "ambitieus"],
    }
  },
  computed: {
    formData: {
      get() {
        return this.$store.state.userProfile.formData
      },
      set(updatedFormData) {
        this.$store.commit("updateFormData", {
          ...updatedFormData,
        })
      },
    },
  },
  methods: {
    toggleParticulierWerk() {
      this.formData.userProfile.openVoorParticulierWerk = !this.formData.userProfile.openVoorParticulierWerk
      this.updateFormData()
    },
    updateArray({ value, array }) {
      const arrayToEdit = this.getArrayToEdit(array)
      const valueExistsInArray = arrayToEdit.includes(value)
      if (valueExistsInArray) {
        arrayToEdit.splice(arrayToEdit.indexOf(value), 1)
        this.updateFormData()
      } else {
        arrayToEdit.push(value)
        this.updateFormData()
      }
    },

    updateFormData() {
      // this.formData = {
      //   ...this.formData,
      //   disciplines: this.selectedDisciplines,
      //   werkgebied: this.selectedProvincies,
      // }
      this.$store.dispatch("debounceUpdateUserProfile")
    },

    getArrayToEdit(array) {
      // ! CANT FIND ARRAY
      let tempArray
      if (array === "voorkeurTypeOpdrachten") tempArray = this.formData.userProfile.voorkeurTypeOpdrachten
      if (array === "werkplek") tempArray = this.formData.userProfile.werkplek
      if (array === "disciplines") tempArray = this.formData.userProfile.disciplines
      if (array === "werkgebied") tempArray = this.formData.userProfile.werkgebied
      if (array === "kwaliteiten") tempArray = this.formData.userProfile.kwaliteiten
      if (array === "tekenprogrammas") tempArray = this.formData.userProfile.tekenprogrammas
      if (!tempArray) throw new Error("Cannot find an array to select, please check the incoming variable")
      return tempArray
    },
  },
}
</script>

<style lang="sass" scoped>
@import '@/utilities/variables.sass'

.fullWidth
  width: 100% !important


.vert
  width: 50%
  display: flex
  flex-direction: column
  .cdt_inputWrapper
    width: 100% !important
    > div
      width: 100%

.componentWrapper
  .line
    align-items: flex-start !important
    display: flex

.componentWrapper
  .list
    width: 100% !important
    max-width: none !important

.wrapperr
  display: flex
  flex-wrap: wrap
  .listItem
    width: 50%
    overflow: hidden
    &:nth-child(even)
      margin-left: -1px

.cdt_note
  padding-top: 10px
  padding-bottom: 10px


.listItem
  padding: 8px 10px
  cursor: pointer
  border: 1px solid rgba(0, 0, 0, 0.6)
  margin-bottom: -1px
  display: flex
  align-items: center
  justify-content: space-between
  font-size: 13px
  position: relative
  opacity: 0.6
  transition: .2s
  &.active
    opacity: 1
    border-color: $cdt_blue
    z-index: 1
    background-color: rgba(42, 108, 249, 0.05)
    color: $cdt_blue
    font-weight: 700
  svg
    transition: .3s
    fill: $cdt_green
    font-size: 14px
    height: 1em
    width: 1em
    &.hidden
      transform: translateX(26px)
</style>
